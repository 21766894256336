<template>
  <base-section id="about-our-product">
    <base-section-heading
      color="grey lighten-2"
      image="android-chrome-512x512.png"
      :title="$ml.get('easy_to_invest')"
    />

    <v-container>
      <v-row>
        <!--<v-col v-for="card in cards" :key="card.title" cols="12" md="4">
          <base-info-card v-bind="card" />
        </v-col>-->
        <v-col cols="12" md="4">
          <v-row no-gutters>
            <v-col cols="9">
              <base-subtitle :title="$ml.get('anonim_reg')" space="1" />
              <base-title
                :title="$ml.get('create_acc_waves')"
                class="text-uppercase"
                space="1"
              />
              <base-divider color="primary" />
              <a href="https://waves.exchange" target="blank"
                >https://waves.exchange</a
              >
              <base-body :text="$ml.get('create_acc_waves_text')" space="6"
                ><slot />
              </base-body>
            </v-col>
            <v-col cols="2">
              <div
                class="display-3 grey--text text--lighten-4 font-weight-bold pr-8"
              >
                01
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="4">
          <v-row no-gutters>
            <v-col cols="9">
              <base-subtitle :title="$ml.get('useful')" space="1" />
              <base-title
                :title="$ml.get('buy_in_market')"
                class="text-uppercase"
                space="1"
              />
              <base-divider color="primary" />
              <!--<a href="https://waves.exchange" target="blank"
                >https://waves.exchange</a>-->
              <base-body :text="$ml.get('buy_in_market_text')" space="6"
                ><slot />
              </base-body>
            </v-col>
            <v-col cols="2">
              <div
                class="display-3 grey--text text--lighten-4 font-weight-bold pr-8"
              >
                02
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="4">
          <v-row no-gutters>
            <v-col cols="9">
              <base-subtitle :title="$ml.get('comfort_payouts')" space="1" />
              <base-title
                :title="$ml.get('get_stable_profit')"
                class="text-uppercase"
                space="1"
              />
              <base-divider color="primary" />
              <!--<a href="https://waves.exchange" target="blank"
                >https://waves.exchange</a
              >-->
              <base-body :text="$ml.get('get_stable_profit_text')" space="6"
                ><slot />
              </base-body>
            </v-col>
            <v-col cols="2">
              <div
                class="display-3 grey--text text--lighten-4 font-weight-bold pr-8"
              >
                03
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: "SectionAboutOurProduct",

  data: () => ({}),
};
</script>
